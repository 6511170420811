<template>
    <div>
        <div class="btn" @click="play" v-if="initcount>=urls.length">play</div>
        <player v-for="(item,index) in urls" :key="index" :active="index==0" :url="item" :isLive="isLive" @canplay="canplay" 
            @initerror="initerror"
            @waiting="waiting"
            @playing="playing"
            @seeking = "seeking"
            @seeded="seeded"
            @ended="ended"
            @error="error"
            @timeupdate="timeupdate"
        ></player>
    </div>
</template>
<script>
import player from './playerSingle.vue'
export default{
    name:'unifriPlayer',
    components:{player},
    data:function(){
        return {
            initcount:0,
            players:[],
            durationSelf:100,
            stopped:true,
            interval:null,
            timeplayed:0,
            seekaction:false
        }
    },
    props:{
        urls:{
            type:Array,
            default:()=>[]
        },
        isLive:{
            type:Boolean,
            default:false
        },
        duration:{
            type:Number,
            default:0
        }
    },
    mounted(){
        let _this = this
        this.interval = setInterval(function(){
            if (!_this.stopped || !_this.seekaction)
                _this.timeplayed += 0.5
        },500)
    },
    methods:{
        canplay(e){
            this.initcount++
            this.players.push(e)
        },
        initerror(){
            this.initcount++
        },
        play(){
            this.players.forEach(v=>{v.play()})
            this.stopped=false
        },
        waiting(){
            this.players.forEach(v=>{v.pause()})
            this.stopped=true
        },
        seeking(){
            this.players.forEach(v=>{v.pause()})
            this.stopped=true
        },
        seeked(){
            this.players.forEach(v=>{v.play()})
            this.stopped=false
        },
        playing(){
            this.players.forEach(v=>{v.play()})
            this.stopped=false
        },
        ended(){
            this.players.forEach(v=>{v.pause()})
            this.stopped=true
        },
        error(){
            this.players.forEach(v=>{v.pause()})
            this.stopped=true
        },
        timeupdate(player){
            if (player.durationSelf>this.durationSelf)
                this.durationSelf = player.durationSelf
        }
    }
}
</script>