<template>
        <video ref="video" 
        preload="metadata" 
        x-webkit-airplay="allow" 
        :autoplay="autoplay" 
        :volume="volume"
        webkit-playsinline playsinline 
        x5-video-player-type="h5-page" 
        :muted="!active" />
</template>
<script>
import flvjs from "flv.js"
import hlsjs from "hls.js"
import axios from 'axios'
export default {
    name:'playerSingle',
    data:function(){
        return {
            player:null,
            supported:true,
            loaderr:false,
            lastDecodedFrame:0,
            destoyedCount:0,
            durationSelf:0
        }
    },
    props:{
        volume:{
            type:Number,
            default:50
        },
        autoplay:{
            type:Boolean,
            default:false
        },
        active:{
            type:Boolean,
            default:false
        },
        url:{
            type:String,
            default:""
        },
        isLive:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        videoType:function(){
            if (this.url.toLowerCase().indexOf('.flv')>0)
                return 'flv'
            if (this.url.toLowerCase().indexOf('.m3u8')>0)
                return 'hls'
            if (this.url.toLowerCase().indexOf('.mp4')>0)
                return 'mp4'
            return 'mp4'
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        isRunUrl(src,callback,count) {
            var c=count||0
            var ajax = function(tmpsrc){
                return new Promise(function(resolve,reject){
                    axios({
                        method:'get',
                        url:tmpsrc
                    }).then(()=>{
                        callback('success')
                    }).catch(()=>{
                        reject()
                    })
                })
            }
            ajax(src).then(()=>{
                callback('success')
            },()=>{
                c++
                //限制尝试10s
                if(c<=50){
                    setTimeout(()=>{
                        this.isRunUrl(src,callback,c)
                    },2000)
                }else{
                    callback('fail')
                }
            })
        },
    destroy(){
       this.destoyedCount+=1
       if (this.player){
          if (this.videoType=='hls'){
            this.player.detachMedia()
            this.player.destroy()
          }
          else{
            this.player.pause()
            this.player.unload()
            this.player.detachMediaElement()
            this.player.destroy()
          }
       }
       this.player = null
    },
    init(){
        let _this=this
        if (this.videoType=='hls' && hlsjs.isSupported){
            this.player = new hlsjs().loadSource
            this.player.attachMediaElement(this.$refs.video)
            this.$refs.video.load()
            this.isRunUrl(_this.url,(status)=>{
                if(status=='success'){
                    _this.player.loadSource(_this.url)
                }
                else{
                    _this.loaderr=true
                    this.$emit('initerror',null)
                }
            })
            this.player.on(hlsjs.Events.ERROR,function(event,data){
                if (data.fatal){
                    switch (data.type){
                        case hlsjs.ErrorTypes.NETWORK_ERROR:
                            _this.player.startLoad()
                            break
                        case hlsjs.ErrorTypes.MEDIA_ERROR:
                            _this.player.recoverMediaError()
                            break
                        default:
                            _this.destroy()
                            // if (_this.destoyedCount>10){
                            //     this.$emit('initerror',null)
                            //     return
                            // }
                            // _this.init()
                            break
                    }
                }
            })
        }
        else if(flvjs.isSupported && (this.videoType=='flv' || this.videoType=='mp4')){
            
            _this.player =  flvjs.createPlayer({
                        type:_this.videoType,
                        url:_this.url,
                        isLive:_this.isLive,
                        hasVideo:true,
                        hasAudio:true
                        },
                        {cors: true, // 是否跨域
                            fixAudioTimestampGap: false,
                            isLive: true,
                            lazyLoad: true,
                            autoCleanupSourceBuffer: true, // 是否自动清理缓存，
                            enableStashBuffer: false, // 关闭IO隐藏缓冲区
                            stashInitialSize: 128, // 减少首帧显示等待时长
                    })
                    _this.player.attachMediaElement(_this.$refs.video)
                    _this.player.load()
                    //断流重连
                    _this.player.on(flvjs.Events.ERROR, () => {
                        //视频出错后销毁重新创建
                        if (_this.player) {
                            _this.destroy()
                        }
                    });
            
            //画面卡死
            // this.player.on("statistics_info", function (res) {
            //     if (_this.lastDecodedFrame == 0) {
            //         _this.lastDecodedFrame = res.decodedFrames;
            //         return;
            //     }
            //     if (_this.lastDecodedFrame != res.decodedFrames) {
            //         _this.lastDecodedFrame = res.decodedFrames;
            //     } else {
                   
            //         _this.lastDecodedFrame = 0;
            //         _this.destroy()
            //         if (_this.destoyedCount>10){
            //             this.$emit('initerror',null)
            //             return
            //         }
            //         _this.init()
                    
            //     }
            // });
        }
        else{
            this.supported = false
            _this.$refs.video.src = _this.url
            _this.$refs.video.muted = true
            _this.$refs.video.load()
        }
        // this.$refs.video.addEventListener('canplay', function() {
        //     _this.$emit('canplay',_this)
        // })
        this.$refs.video.oncanplay = function(){
            _this.$emit('canplay',_this)
        }
        this.$refs.video.addEventListener('timeupdate', async function() {
            let tmpduration=0
            if (_this.$refs.video.duration == Infinity) {
                tmpduration = 10*1000
            }
            else{
                tmpduration = _this.$refs.video.duration 
            }
            if (tmpduration>_this.durationSelf ){
                _this.durationSelf = tmpduration
                _this.$emit('timeupdate',_this)
            }
        })
        this.$refs.video.addEventListener('seeking', function() {
            _this.$emit('seeking',_this)
        })
        // 11、seeked：查找结束。当用户已经移动/跳跃到视频中新的位置时触发
        this.$refs.video.addEventListener('seeked', function() {
            _this.$emit('seeked',_this)
         })
        // 12、waiting：视频加载等待。当视频由于需要缓冲下一帧而停止，等待时触发
         this.$refs.video.addEventListener('waiting', function() {
             _this.$emit('waiting',_this)
        })
        // 13、playing：当视频在已因缓冲而暂停或停止后已就绪时触发
        this.$refs.video.addEventListener('playing', function() {
            _this.$emit('playing',_this)
        })
        // 15、ended：播放结束
        this.$refs.video.addEventListener('ended', function() {
            _this.$emit('ended',_this)
        })
        // 16、error：播放错误
        this.$refs.video.addEventListener('error', function() {
            _this.loaderr=true
        })
    },
    play(){
       
        this.$refs.video.play()
        
    },
    pause(){
        this.$refs.video.pause()
    },
    seek(time){
        let minseek = 1000000
        let maxseek=0
        if (this.$refs.video.seekable.end(0)<minseek){
            minseek = this.$refs.video.seekable.end(0)
        }
        if (this.$refs.video.seekable.start(0)>maxseek){
            maxseek = this.$refs.video.seekable.start(0)
        }
        let seektime = time
        if (seektime<maxseek) seektime = maxseek
        if (seektime>minseek) seektime = minseek
        this.$refs.video.currentTime = time
    },
    
  }
}
</script>