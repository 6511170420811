<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <videos :isLive="true" :urls="urls"></videos>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import videos from './components/players.vue'
export default {
  name: 'App',
  components: {
    HelloWorld,
    videos
  },
  data:function(){
    return {
      //urls:['http://vjs.zencdn.net/v/oceans.mp4']
      urls:['https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv ','https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv']
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
